import axios from 'axios'
import Cookies from 'js-cookie'
import { eventEmitter } from '../../common'

/**
 * Class representing an ESputnik Tracker.
 */
class ESputnikTracker {

  loadScript () {
    let s = document.createElement('script')
    s.async = true, s.src = 'https://statics.esputnik.com/scripts/5F4452D270B0476698F17A71F3E5DD20.js'
    s.onload = () => {
      eventEmitter.emit('EsputnikLoaded')
      window.sessionStorage.setItem('EsputnikLoaded', 'yes')
    }
    let r = document.scripts[0]
    r.parentNode.insertBefore(s, r)
    let f = function () {
      f.c(arguments)
    }
    f.q = []
    f.c = function () {
      f.q.push(arguments)
    }
    window['eS'] = window['eS'] || f

    eS('init', { TRACKING: false, RECOMS: true })
  }

  /**
   * Событие просмотра страницы
   * @constructor
   */
  PageView () {
    eS('sendEvent', 'PageView', {
      'PageView': {}
    })
  }

  /**
   * Отправляем запрос об изменении корзины в ESputnik
   */
  sendBasketToESputnik () {
    //Формируем GUID и записываем данные в куки
    var uniqueId = this.generateUniqueId()
    Cookies.set('BITRIX_SM_BASKET_STATE_GUID', uniqueId, { expires: 365 })
    axios.post(`/local/api/eSputnik/basketItemsList.php`, `guid=${uniqueId}`)
      .then(response => {
        //Отправка в ESputnik
        eS('sendEvent', 'StatusCart', {
          'StatusCart': response.data,
          'GUID': uniqueId
        })
      })
      .catch(error => {
        // Обработка ошибок при отправке запроса
        console.error(error)
      })
  }

  /**
   * Событие аналитики "CategoryPage"
   * Передает идентификатор категории, на странице которой находится пользователь
   * @param {Number} categoryId
   * @returns {boolean|void}
   * @constructor
   */
  CategoryPage (categoryId) {
    if (categoryId == null) return false
    try {
      eS('sendEvent', 'CategoryPage', {
        'CategoryPage': {
          'categoryKey': categoryId
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - CategoryPage')
        console.dir({
          'CategoryPage': {
            'categoryKey': categoryId
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "CategoryPage"')
    }
  }

  /**
   * Add to wish list event
   *
   * eS('sendEvent', 'AddToWishlist', {
   *         'AddToWishlist': {
   *             'productKey': '24-MB02',
   *             'price': '153',
   *             'isInStock': 1
   *         }
   * });
   *
   * @param productKey
   * @param price
   * @param isInStock
   * @constructor
   */
  AddToWishlist (productKey, price, isInStock) {
    try {
      eS('sendEvent', 'AddToWishlist', {
        'AddToWishlist': {
          'productKey': productKey,
          'price': price,
          'isInStock': parseInt(isInStock, 10)
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - AddToWishlist')
        console.dir({
          'AddToWishlist': {
            'productKey': productKey,
            'price': price,
            'isInStock': parseInt(isInStock, 10)
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "AddToWishlist"')
    }
  }

  /**
   * Событие аналитики "ProductPage"
   * Передает данные по товару со страницы карточки товара
   * @param {object} productData
   * @constructor
   */
  ProductPage (productData) {
    try {
      let id = productData[0]
      let price = productData[1]
      let inStock = productData[2]

      eS('sendEvent', 'ProductPage', {
        'ProductPage': {
          'productKey': id,
          'price': String(price),
          'isInStock': inStock
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - ProductPage')
        console.dir({
          'ProductPage': {
            'productKey': id,
            'price': String(price),
            'isInStock': inStock
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "ProductPage"')
    }
  }

  /**
   * Генерация уникального GUID
   * @returns {string}
   */
  generateUniqueId () {
    const formatString = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    return formatString.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  /**
   * Событие отправки параметров пользователя
   * @param data
   */
  sendCustomerData (data) {
    eS('sendEvent', 'CustomerData', {
      'CustomerData': data
    })
  }

  SearchRequest (query, has_items) {
    try {
      eS('sendEvent', 'SearchRequest', {
        'SearchRequest': {
          'search': query,
          'isFound': has_items
        }
      })
    } catch (e) {
      console.error('Error eSputnik send event "SearchRequest"')
    }
  }
}

export default ESputnikTracker